<template>
  <div>
    <h3>home组件</h3>
  </div>
</template>
<script>
export default {
  name: "MyHome",
};
</script>
