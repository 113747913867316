<template>
  <div>
    <h3>about组件</h3>
  </div>
</template>
<script>
export default {
  name: "MyAbout",
};
</script>
